import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from './Link';
import Logo from './Logo';

export default () => {
  const { wp, footerNav, footerLegal } = useStaticQuery(graphql`
    {
      wp {
        options {
          options {
            footer {
              copyrightText
            }
          }
        }
      }
      footerNav: wpMenu(slug: { eq: "footer" }) {
        name
        menuItems {
          nodes {
            id
            label
            url
            childItems {
              nodes {
                id
                label
                url
              }
            }
          }
        }
      }
      footerLegal: wpMenu(slug: { eq: "footer-legal" }) {
        name
        menuItems {
          nodes {
            id
            label
            url
          }
        }
      }
    }
  `);

  return !!footerNav && !!footerNav.menuItems && !!footerNav.menuItems.nodes ? (
    <footer className="footer bg-white text-black">
      <div className="container">
        <div className="lg:flex flex-wrap justify-between">
          <Link to="/" className="footer__logo mb-12 hidden md:block">
            <Logo />
          </Link>

          <div className="rhs flex justify-between lg:justify-start flex-wrap footer__nav__wrapper">
            {footerNav.menuItems.nodes.map((ni) => (
              <nav className="footer__nav" key={ni.id}>
                <h4>{ni.label}</h4>
                <div>
                  {ni.childItems.nodes.map((child) => (
                    <div key={child.id}>
                      <Link activeClassName={`is-active`} partiallyActive={true} className="footer__nav__item hover:opacity-75 block" to={child.url} dangerouslySetInnerHTML={{ __html: child.label }} />
                    </div>
                  ))}
                </div>
              </nav>
            ))}
            <nav className="footer__nav hidden-at-500px">
              <h4>Legal</h4>
              <div>
                {footerLegal.menuItems.nodes.map((ni) => (
                  <div key={ni.id + 'small'}>
                    <Link activeClassName={`is-active`} partiallyActive={true} className="footer__nav__item hover:opacity-75 block" to={ni.url} dangerouslySetInnerHTML={{ __html: ni.label }} />
                  </div>
                ))}
              </div>
            </nav>
          </div>
        </div>

        <div className="flex flex-col md:flex-row text-center md:text-left flex-wrap justify-between items-center footer__bottom">
          <div>{wp.options.options.footer.copyrightText}</div>

          <nav className="footer__legal hidden flex-at-500px mt-3">
            {footerLegal.menuItems.nodes.map((ni) => (
              <div key={ni.id} className="mx-5 md:mr-0">
                <Link to={ni.url} activeClassName="text-purple" className="footer__nav__item hover:opacity-75 " dangerouslySetInnerHTML={{ __html: ni.label }} />
              </div>
            ))}
          </nav>
        </div>
      </div>
    </footer>
  ) : null;
};
