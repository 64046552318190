import React, { useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import MobileNavItems from './MobileNavItems';
import Social from './Social';

const MobileNav = ({ isShown, isScrolled, hideMobileNav, data }) => {
  useEffect(() => {
    if (isShown) {
      document.body.classList.remove('nav-scroll');
    } else if (isScrolled) {
      document.body.classList.add('nav-scroll');
    }
  }, [isShown, isScrolled]);

  return (
    <>
      <div className={`lg:hidden mobile-nav bg-black text-white fixed inset-0 overflow-hidden overflow-y-auto ${isShown && 'is-open'}`}>
        <div className="flex flex-col justify-between min-h-full">
          <div className="container flex flex-col flex-auto pt-20 pb-16 nav-container">
            <MobileNavItems clickFunction={hideMobileNav} />
          </div>

          <div className={`bottom-container bg-white text-black py-12`}>
            <div className="container flex flex-col items-center">
              <div className="text-16px font-medium text-center mb-4" dangerouslySetInnerHTML={{ __html: data.wp.options.options.footer.copyrightText.replace(' All', '<br>All') }} />
              <Social />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const query = graphql`
  query MobileNavigation {
    wp {
      options {
        options {
          footer {
            copyrightText
          }
        }
      }
    }
  }
`;

export default (props) => <StaticQuery query={`${query}`} render={(data) => <MobileNav data={data} {...props} />} />;
