import React, { useEffect, useRef } from 'react';
import Link from './Link';
import styled from 'styled-components';
import tw from 'twin.macro';
import { gsap } from 'gsap/dist/gsap';
import media from '../utils/media';
import useKeyPress from '../utils/useKeyPress';

const Background = styled.div`
  ${tw`absolute inset-0`}
  z-index:48;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  top: 100%;
  height: 80vh;
  opacity: 0;

  ${media.lessThan('lg')`
    ${tw`hidden`}
  `}
`;

const Border = styled.div`
  ${tw`w-full`}
  height: 3px;
  background-color: #141414;
`;

const SubnavStyled = styled.div`
  ${tw`max-w-screen bg-d-black text-white z-50 relative`}
  padding-top:139px;
  ${media.lessThan('lg')`
    .container {padding:0}
  `}
`;

export default function Subnav({ data, handleClick }) {
  const escPress = useKeyPress('Escape');
  const subnavEl = useRef();
  const bgEl = useRef();
  const featRefs = [useRef(), useRef(), useRef(), useRef(),useRef(), useRef()];

  useEffect(() => {
    if (escPress === true) {
      handleClick();
    }
  }, [escPress]);

  useEffect(() => {
    const tl = gsap
      .timeline({
        defaults: {
          // children inherit these defaults
          duration: 0.7,
          ease: 'power4.out',
        },
      })
      .fromTo(subnavEl.current, { zIndex: -1, y: '-100%', opacity: 0.75 }, { zIndex: 1, y: '0%', opacity: 1 })
      .fromTo(
        featRefs.map(({ current }) => current),
        { x: '-10%', opacity: 0, stagger: 0.1 },
        { x: '0%', opacity: 1, stagger: 0.1 },
        0.2
      )
      .to(bgEl.current, { opacity: 1, duration: 5 }, 0.3);

    return () => {
      return tl.kill();
    };
  }, []);

  return (
    <>
      <Background ref={bgEl} onClick={() => handleClick()} />
      <SubnavStyled ref={subnavEl} className="subnav opacity-0">
        <Border />

        <div className="container">
          <div className="subnav-grid">
            <div ref={featRefs[0]} className="uppercase text-11px font-bold py-3">
              <div className="px-6">Overview</div>
            </div>
            <div ref={featRefs[1]} className="uppercase text-11px font-bold py-3">Features</div>
          </div>
        </div>

        <Border />
        <div className="container">
          <div className="subnav-grid">
            <div ref={featRefs[2]} className="flex subnav-item--big">
              <SubnavItem handleClick={handleClick} ni={data[0]} icon="overview" />
            </div>
            <div>
              <div className="flex flex-wrap features-subnav -ml-6">
                {data.slice(1).map((ni, i) => (
                  <div ref={featRefs[i + 3]} className="lg:w-1/3 w-full subnav-item--small" key={'subnav' + ni.url}>
                    <SubnavItem handleClick={handleClick} ni={ni} icon={ni.label.toLowerCase()} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </SubnavStyled>
    </>
  );
}

const SubnavItemStyled = styled.div`
  position: relative;
  .arrow {
    ${tw`absolute top-0 right-0 w-full`}
    width:13px;
    height: 13px;
    margin-top: 10px;
    margin-right: 10px;
    img {
      transform: translate(-2px, 2px);
      transition: transform 0.2s ease-in-out;
    }
  }
  &:hover .arrow img {
    transform: none;
  }
  &:active .arrow img {
    transform: translate(2px, -2px);
  }
`;

function SubnavItem({ ni, icon, handleClick }) {
  return (
    <SubnavItemStyled onClick={() => handleClick()} className=" flex-auto flex flex-col">
      <Link key={ni.url + icon} to={ni.url} className="subnav-item lg:pt-12 lg:pb-16 flex-auto flex items-center lg:block">
        <div className="arrow">
          <img className="w-full relative" src={require('assets/images/square-arrow.svg')} alt="Arrow" />
        </div>

        <img src={require(`assets/images/product-icons/${icon}.svg`)} alt={ni.label} />
        <h3 className="text-22px font-semibold lg:mt-6 lg:mb-3" dangerouslySetInnerHTML={{ __html: ni.label }} />
        <p className="lg:max-w-xxs" dangerouslySetInnerHTML={{ __html: ni.description }} />
      </Link>
    </SubnavItemStyled>
  );
}
