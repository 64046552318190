import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from './Link';
import SubnavMobile from './SubnavMobile';

const MobileNavItems = ({ clickFunction }) => {
  const { mobile, header } = useStaticQuery(graphql`
    {
      mobile: wpMenu(slug: { eq: "mobile" }) {
        name
        menuItems {
          nodes {
            id
            label
            url
          }
        }
      }
      header: wpMenu(slug: { eq: "header" }) {
        name
        menuItems {
          nodes {
            id
            label
            url
            childItems {
              nodes {
                id
                label
                url
                description
              }
            }
          }
        }
      }
    }
  `);

  const { nodes } = mobile.menuItems;
  const { nodes: headerNodes } = header.menuItems;
  const productSubNav = headerNodes.filter((itm) => itm.childItems.nodes.length > 0).pop();

  return (
    <nav className="flex flex-col">
      {productSubNav && <SubnavMobile handleClick={clickFunction} data={productSubNav.childItems.nodes} />}

      {nodes.map((item) => (
        <div key={'mobile' + item.id} className="mobile-nav-item">
          {!item.url.includes('demo') && (
            <Link onClick={() => clickFunction()} to={item.url} activeClassName={`is-active`} partiallyActive={true} className="flex items-center justify-between font-semibold text-26px">
              <div>{item.label}</div>
              <img className="w-full relative" src={require('assets/images/square-arrow-white.svg')} alt="Arrow" style={{ maxWidth: '13px' }} />
            </Link>
          )}
          {item.url.includes('demo') && (
            <Link to={item.url} className="button button--purple button--h57 mt-6 min-w-full">
              {item.label}
            </Link>
          )}
        </div>
      ))}
    </nav>
  );
};

export default MobileNavItems;
