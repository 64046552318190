import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Link from './Link';
import FacebookIcon from '../assets/images/social/facebook.inline.svg';
import InstagramIcon from '../assets/images/social/instagram.inline.svg';
import LinkedinIcon from '../assets/images/social/linkedin.inline.svg';
import TwitterIcon from '../assets/images/social/twitter.inline.svg';
import styled from 'styled-components';

const SocialStyled = styled.div`
  max-width: 215px;
  margin: auto;
  svg rect {
    transition: fill 0.11s;
    &:active {
      opacity: 0.8;
    }
  }

  a:hover svg rect {
    fill: var(--purple);
  }
`;

const Social = ({ data }) => {
  const Links = data.wp.options.options.social;
  return (
    <SocialStyled className="social flex justify-center -ml-2 mx-auto">
      {Object.keys(Links).map((s, i) => {
        const url = Object.values(Links)[i];

        return url ? (
          <Link key={s} to={url || '/'}>
            {s === 'facebook' && (
              <div className="pl-2">
                <FacebookIcon />
              </div>
            )}
            {s === 'instagram' && (
              <div className="pl-2">
                <InstagramIcon />
              </div>
            )}
            {s === 'linkedin' && (
              <div className="pl-2">
                <LinkedinIcon />
              </div>
            )}
            {s === 'twitter' && (
              <div className="pl-2">
                <TwitterIcon />
              </div>
            )}
          </Link>
        ) : null;
      })}
    </SocialStyled>
  );
};

const query = graphql`
  query Social {
    wp {
      options {
        options {
          social {
            twitter
            facebook
            instagram
            linkedin
          }
        }
      }
    }
  }
`;

export default (props) => <StaticQuery query={`${query}`} render={(data) => <Social data={data} {...props} />} />;
