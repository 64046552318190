import React from 'react';
import Link from './Link';
import styled from 'styled-components';
import tw from 'twin.macro';
// import media from '../utils/media';

const Border = styled.div`
  ${tw`w-full`}
  height: 2px;
  background-color: #313131;
`;

export default function Subnav({ data, handleClick }) {
  return (
    <div className="subnav-mobile mb-8">
      <div>
        <div className="text-white text-12px font-bold py-5 uppercase">Overview</div>
        <SubnavItem handleClick={handleClick} ni={data[0]} icon="overview" className="type--overview" />
      </div>

      <div>
        <div className="text-white text-12px font-bold py-5 mt- uppercase">Features</div>

        {data.slice(1).map((ni, i) => (
          <div key={'subnav-mobile' + ni.url}>
            <Border />
            <SubnavItem handleClick={handleClick} ni={ni} icon={ni.label.toLowerCase()} className="type--feature" />
            {i === data.length - 2 && <Border />}
          </div>
        ))}
      </div>
    </div>
  );
}

const SubnavItemStyled = styled.div`
  ${tw`py-8 flex-auto flex flex-col relative`}

  .icon {
    max-width: 53px;
  }

  &.type--overview {
    &:before {
      content: '';
      ${tw`absolute inset-0 transition duration-200 ease-in-out`}
      background-color: #1f1f1f;
      z-index: -1;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    &:active,
    &:hover {
      &:before {
        background-color: #2c2c2c;
      }
    }
  }
`;

function SubnavItem({ ni, icon, handleClick, className = '' }) {
  return (
    <SubnavItemStyled onClick={() => handleClick()} className={className}>
      <Link key={ni.url + icon} to={ni.url} className="subnav-item flex items-center">
        <img src={require(`assets/images/product-icons/${icon}.svg`)} alt={ni.label} className="icon mr-6 block" />
        <div>
          <h3 className="text-22px font-medium lg:mt-6 lg:mb-3" dangerouslySetInnerHTML={{ __html: ni.label }} />
          <p className="text-14px sm:text-16px" style={{ lineHeight: '1.45' }} dangerouslySetInnerHTML={{ __html: ni.description }} />
        </div>
      </Link>
    </SubnavItemStyled>
  );
}
