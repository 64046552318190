import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from './Link';
import Subnav from './Subnav';

const HeaderNav = ({ subnavOpen, toggleSubnav, handleClick }) => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "header" }) {
        name
        menuItems {
          nodes {
            id
            label
            url
            childItems {
              nodes {
                id
                label
                url
                description
              }
            }
          }
        }
      }
    }
  `);

  const { nodes } = wpMenu.menuItems;

  return (
    <nav className="flex main-nav align-center">
      {nodes.map((item, i) => (
        <div key={item.id} className={(i > 0 ? 'md:ml-8 ml-6' : ' ') + ' font-medium text-16px flex items-center '}>
          <Link
            onClick={(e) => {
              item.childItems.nodes.length > 0 && e.preventDefault();
              handleClick && handleClick();
              item.childItems.nodes.length > 0 && toggleSubnav(i);
            }}
            to={item.url}
            activeClassName={`is-active`}
            partiallyActive={true}
            className={`flex items-center nav-item-${item.label.toLowerCase()}`}
          >
            <div>{item.label}</div>
            {item.childItems.nodes.length > 0 && (
              <svg style={{ transform: subnavOpen === i ? 'rotate(180deg)' : '' }} className="nav-chev" viewBox="0 0 11.826 7.801" xmlns="http://www.w3.org/2000/svg">
                <path d="m5.913 7.801-5.913-6.449 1.475-1.352 4.438 4.842 4.439-4.842 1.474 1.352z" fill="#fff" />
              </svg>
            )}
          </Link>

          {item.childItems.nodes.length > 0 && subnavOpen === i && (
            <div className={`absolute left-0 w-full top-0`} style={{ zIndex: -1 }}>
              <Subnav handleClick={handleClick} data={item.childItems.nodes} />
            </div>
          )}
        </div>
      ))}
    </nav>
  );
};

export default HeaderNav;
