import React, { useState, useEffect } from 'react';
import Link from './Link';
import MobileNav from './MobileNav';
import HeaderNav from './HeaderNav';
import Logo from './Logo';
import useDocumentScrollThrottled from '.././utils/useDocumentScrollThrottled';

export default function Header() {
  const [subnavOpen, setSubnavOpen] = useState(-1);
  const toggleSubnav = (i) => (subnavOpen !== i ? setSubnavOpen(i) : setSubnavOpen(-1));

  const [navIsOpen, setNavIsOpen] = useState(false);

  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);

  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 50;

  useDocumentScrollThrottled((callbackData) => {
    if (typeof window === 'undefined') return;
    const isBigHeader = window.innerWidth >= 1024;

    if (isBigHeader) {
      setShouldShowShadow(false);
      setShouldHideHeader(false);
      return;
    }

    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowShadow(currentScrollTop > 2);

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const scrolledStyle = shouldShowShadow ? 'is-scrolled' : '';
  const hiddenStyle = shouldHideHeader ? 'is-hidden' : '';

  const handleClick = () => {
    setNavIsOpen(false);
    setSubnavOpen(-1);
  };

  const hideMobileNav = () => setNavIsOpen(false);

  useEffect(() => {
    if (typeof window !== 'object') {
      return false;
    }

    function handleResize() {
      setSubnavOpen(-1);
      hideMobileNav();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <div className="relative h-auto">
      <header id="js-header" className={`c-header ${scrolledStyle} ${hiddenStyle} ${navIsOpen ? 'mobile-nav-is-open' : ''} ${subnavOpen > -1 ? 'subnav-open' : ''}`}>
        <div className="container flex items-center justify-between align-center">
          <div onClick={handleClick}>
            <Link to="/" className="c-header__logo relative">
              <Logo className="header-logo--dark" />
              <Logo isLight={true} className="header-logo--light absolute inset-0 opacity-0" />
            </Link>
          </div>
          {/* <div className="lg:flex align-center ">
            <Link to="https://app.p.ai/sign-in" className="button--demo button button--waves xl:ml-10 ml-8">
              Login
            </Link>
          </div> */}
          <div className="hidden lg:flex align-center ">
            <HeaderNav handleClick={handleClick} toggleSubnav={toggleSubnav} subnavOpen={subnavOpen} />
            <Link to="/demo/" className="button--demo button button--waves xl:ml-10 ml-8">
              Book a demo
            </Link>
          </div>

          <div className="block lg:hidden">
            <button onClick={() => setNavIsOpen(!!!navIsOpen)} className={`tg-hamburger ${navIsOpen && 'is-active active'}`} type="button">
              <div>
                <span /> <span />
              </div>
            </button>
          </div>
        </div>
      </header>
      <div className="header-height"></div>

      <MobileNav isShown={navIsOpen} hideMobileNav={hideMobileNav} />
    </div>
  );
}
