import { generateMedia } from 'styled-media-query';

const media = generateMedia({
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
});

export default media;
